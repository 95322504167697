<template>
  <div class="main_content">
    <top_view></top_view>
    <div class="main">
      <!--      <div class="top">
              <div class="logo">
                <v-img :src="require('../../../image/top_title.png')"></v-img>
              </div>
            </div>-->
      <div class="policy">
        <div class="title">{{ dataForm.clauseName }}</div>
        <div class="seed">
          <el-image :src="require('@/views/index/img/seed.png')"></el-image
          >
          {{ dataForm.browseNum }}
        </div>
        <div class="info">
          <div class="info-left">
            <el-descriptions :column="2" size="normal" class="info-left-info">
              <el-descriptions-item label="文号">{{ dataForm.documentNum }}
              </el-descriptions-item>
              <el-descriptions-item label="发布单位">{{ dataForm.deptName }}
              </el-descriptions-item>
              <el-descriptions-item label="责任部门" :span="2">{{ dataForm.responsibleDept }}
              </el-descriptions-item>
              <el-descriptions-item label="责任科室" :span="2">{{ dataForm.department }}
              </el-descriptions-item>

              <el-descriptions-item label="印发日期">{{ dataForm.printedTime }}
              </el-descriptions-item>
              <el-descriptions-item label="条款类别">{{ dataForm.policyCategory }}
              </el-descriptions-item>
              <el-descriptions-item label="条款类型">{{ dataForm.policyType }}
              </el-descriptions-item>
              <el-descriptions-item label="支持行业">{{ dataForm.supportType }}
              </el-descriptions-item>
              <el-descriptions-item label="主体规模">{{ dataForm.companyType }}
              </el-descriptions-item>
              <el-descriptions-item label="主体性质">{{ dataForm.companyNature }}
              </el-descriptions-item>
              <el-descriptions-item label="适用地区">{{ dataForm.address }}
              </el-descriptions-item>
              <el-descriptions-item label="咨询电话">{{ dataForm.phone }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="info-right">
            <div class="info-right-valid" v-if="dataForm.isValid == 1">
              <div>实施日期</div>
              <div>长期有效</div>
            </div>

            <div class="info-right-valid" v-if="dataForm.isValid == 0">
              <div>实施日期</div>
              <div>至 {{ dataForm.validEndTime }}</div>
            </div>

            <div
                v-if="dataForm.cashType == '免申即享'"
                style="margin-top: 20px"
            >
              <img src="@/views/index/image/msjx1.png"/>
            </div>
          </div>
        </div>
      </div>

      <div class="clause-box">
        <el-tabs v-model="active">
          <el-tab-pane label="要点详情">
            <div class="title">具体内容</div>
            <div class="content">{{ dataForm.policyPoints }}</div>
            <div class="title">兑现额度说明</div>
            <div class="content">{{ dataForm.cashLimit }}</div>
            <!-- <div class="title">要点解读</div>
                <div class="content last">{{dataForm.pointsExplain}}</div> -->
          </el-tab-pane>
          <el-tab-pane label="申报指南" v-if="dataForm.cashType == '申报兑现'">
            <div class="title">申报条件</div>
            <div class="content last">{{ dataForm.reportProcess }}</div>
            <div class="title">政府内部流转流程</div>
            <div class="content">
              {{ dataForm.moveProcess.replace(/\,/g, "→") }}
            </div>
            <div class="title">申报材料</div>
            <div class="content last">{{ dataForm.reportMaterial }}</div>
          </el-tab-pane>
          <el-tab-pane label="原文附件">
            <div class="down-item" v-for="item in fileList1" :key="item.id">
              国家级条款：
              <a :href="'/file/' + item.fileName" target="_blank">
                <div style="display: inline-block" class="down-item-left">
                  <el-image :src="require('../../../img/file.png')"></el-image>
                </div>
                <div style="display: inline-block" class="down-item-center">
                  {{ item.fileName.slice(14) }}
                </div>
                <div style="display: inline-block" class="down-item-right">
                  <el-image :src="require('../../../img/download.png')"></el-image>
                </div>
              </a>
            </div>
            <div class="down-item" v-for="item in fileList2" :key="item.id">
              省级条款：
              <a :href="'/file/' + item.fileName" target="_blank">
                <div style="display: inline-block" class="down-item-left">
                  <el-image :src="require('../../../img/file.png')"></el-image>
                </div>
                <div style="display: inline-block" class="down-item-center">
                  {{ item.fileName.slice(14) }}
                </div>
                <div style="display: inline-block" class="down-item-right">
                  <el-image :src="require('../../../img/download.png')"></el-image>
                </div>
              </a>
            </div>
            <div class="down-item" v-for="item in fileList3" :key="item.id">
              市级条款：
              <a :href="'/file/' + item.fileName" target="_blank">
                <div style="display: inline-block" class="down-item-left">
                  <el-image :src="require('../../../img/file.png')"></el-image>
                </div>
                <div style="display: inline-block" class="down-item-center">
                  {{ item.fileName.slice(14) }}
                </div>
                <div style="display: inline-block" class="down-item-right">
                  <el-image :src="require('../../../img/download.png')"></el-image>
                </div>
              </a>
            </div>
            <div class="down-item" v-for="item in fileList4" :key="item.id">
              县级条款：
              <a :href="'/file/' + item.fileName" target="_blank">
                <div style="display: inline-block" class="down-item-left">
                  <el-image :src="require('../../../img/file.png')"></el-image>
                </div>
                <div style="display: inline-block" class="down-item-center">
                  {{ item.fileName.slice(14) }}
                </div>
                <div style="display: inline-block" class="down-item-right">
                  <el-image :src="require('../../../img/download.png')"></el-image>
                </div>
              </a>
            </div>
          </el-tab-pane>
          <el-tab-pane label="条款解读">
            <div class="down-item" v-for="item in fileList5" :key="item.id">
              <a :href="'/file/' + item.fileName" target="_blank">
                <div style="display: inline-block" class="down-item-left">
                  <el-image :src="require('../../../img/file.png')"></el-image>
                </div>
                <div style="display: inline-block" class="down-item-center">
                  {{ item.fileName.slice(14) }}
                </div>
                <div style="display: inline-block" class="down-item-right">
                  <el-image :src="require('../../../img/download.png')"></el-image>
                </div>
              </a>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <suspension_btn/>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      active: 0,
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      dataForm: {
        id: 0,
        clauseName: "",
        documentNum: "",
        responsibleDept: "",
        department: "",
        phone: "",
        address: "",
        deptName: "",
        companyType: "",
        companyNature: "",
        supportType: "",
        policyType: "",
        policyCategory: "",
        policyPoints: "",
        pointsExplain: "",
        cashLimit: "",
        reportProcess: "",
        moveProcess: "",
        reportMaterial: "",
        policyId: "",
        browseNum: 0,
        validStartTime: "",
        validEndTime: "",
        printedTime: "",
        cashType: "",
        isValid: 0,
      },
    }
  },
  activated() {
    this.dataForm.id = this.$route.query.id;
    this.getClauseInfo();
  },
  methods: {
    getClauseInfo() {
      this.$http({
        url: this.$http.adornUrl("/app/huiqiclause/info"),
        method: "post",
        data: this.$http.adornData({clauseId: this.dataForm.id}),
      }).then((data) => {
        this.dataForm.clauseName = data.huiqiClause.clauseName;
        this.dataForm.responsibleDept = data.huiqiClause.responsibleDept;
        this.dataForm.department = data.huiqiClause.department;
        this.dataForm.documentNum = data.huiqiClause.documentNum;
        this.dataForm.phone = data.huiqiClause.phone;
        this.dataForm.address = data.huiqiClause.address;
        this.dataForm.companyType = data.huiqiClause.companyType;
        this.dataForm.companyNature = data.huiqiClause.companyNature;
        this.dataForm.policyType = data.huiqiClause.policyType;
        this.dataForm.policyCategory = data.huiqiClause.policyCategory;
        this.dataForm.policyPoints = data.huiqiClause.policyPoints;
        this.dataForm.pointsExplain = data.huiqiClause.pointsExplain;
        this.dataForm.cashLimit = data.huiqiClause.cashLimit;
        this.dataForm.reportProcess = data.huiqiClause.reportProcess;
        this.dataForm.moveProcess = data.huiqiClause.moveProcess;
        this.dataForm.reportMaterial = data.huiqiClause.reportMaterial;
        this.dataForm.policyId = data.huiqiClause.policyId;
        this.dataForm.browseNum = data.huiqiClause.browseNum;
        this.dataForm.supportType = data.huiqiClause.supportType;
        this.dataForm.cashType = data.huiqiClause.cashType;
        this.$http({
          url: this.$http.adornUrl("/app/huiqipolicy/info"),
          method: "post",
          data: this.$http.adornData({policyId: this.dataForm.policyId}),
        }).then((data) => {
          this.dataForm.printedTime = data.huiqiPolicy.printedTime;
          this.dataForm.validStartTime = data.huiqiPolicy.validStartTime;
          this.dataForm.deptName = data.huiqiPolicy.deptName;
          this.dataForm.isValid = data.huiqiPolicy.isValid;
          this.dataForm.validEndTime = data.huiqiPolicy.validEndTime;

          this.fileList = data.huiqiPolicy.fileList;
          this.fileList1 = data.huiqiPolicy.countryFileList;
          this.fileList2 = data.huiqiPolicy.provinceFileList;
          this.fileList3 = data.huiqiPolicy.cityFileList;
          this.fileList4 = data.huiqiPolicy.townFileList;
          this.fileList5 = data.huiqiPolicy.pointsExplainFileList;
        });
      });
    },
  }
}
</script>

<style lang="scss" scoped>

.main_content{
  background: #f0f4f8;
}
.main {
  width: 1300px;
  margin: 0 auto;
  margin-top: 40px;

  .top {
    padding-top: 96px;
    padding-bottom: 70px;

    .logo {
      width: 474px;
      margin: 0 auto;
    }
  }
}

.policy {
  padding: 20px;
  background-color: white;
  border-radius: 20px;

  .title {
    font-size: 28px;
    color: #333;
    line-height: 1.5;
    font-weight: bold;
  }

  .seed {
    margin-top: 10px;

    .el-image {
      vertical-align: middle;
      margin-right: 10px;
    }

    color: #999;
  }

  .info {
    background-color: #f0f4f8;
    display: flex;
    padding: 30px;
    border-radius: 5px;
    margin-top: 10px;

    .info-left {
      flex-grow: 1;

      ::v-deep .info-left-info {
        .el-descriptions__body {
          background-color: transparent;
        }
      }
    }

    .info-right {
      flex-shrink: 0;

      .info-right-valid {
        width: 270px;
        height: 114px;
        background: url("../../../img/policy_valid.png") no-repeat;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center; //水平居中
        justify-content: center; //垂直居中
        line-height: 2;
        font-size: 17px;
      }
    }
  }
}

.clause-box {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;

  ::v-deep .el-tabs__item {
    font-size: 22px;
    height: 50px;
    position: relative;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1156a3;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bold;
    color: #1156a3;
  }

  ::v-deep .el-tabs__content {
    overflow: inherit;
  }

  .title {
    line-height: 1;
    border-left: solid 3px #1156a3;
    margin: 30px 0 20px;
    font-size: 20px;
    columns: #333;
    padding-left: 10px;
    font-weight: bold;
  }

  .content {
    margin-bottom: 30px;
    font-size: 20px;
    color: #333;
    line-height: 1.8;
  }

  .content.last {
    margin-bottom: 20px;
  }

  .down-item {
    display: flex;
    cursor: pointer;
    margin: 15px 0;

    .el-image {
      vertical-align: middle;
    }

    .down-item-left {
      margin-right: 10px;
    }

    .down-item-center {
      margin-right: 10px;
    }
  }
}
</style>